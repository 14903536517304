import { Injectable } from '@angular/core';
import moment from 'moment';
import { DayOfWeek } from '../enums/day-of-week.enum';

@Injectable({
  providedIn: 'root',
})
export class ReadingWeekStartDayService {
  static GetReadingWeekInfo(startDay: DayOfWeek) {
    const readingWeekStartDay = startDay ?? DayOfWeek.Sunday;
    const currentDay = moment().weekday();
    const shouldPickPreviousWeek = readingWeekStartDay - currentDay === 1;
    const weekStart = shouldPickPreviousWeek
      ? moment().startOf('week').set('weekday', readingWeekStartDay).subtract(1, 'week')
      : moment().startOf('week').set('weekday', readingWeekStartDay);

    return {
      readingWeekStartDay,
      readingStartDate: weekStart,
      readingEndDate: weekStart.clone().add(1, 'week').subtract(1, 'day').endOf('day'),
    };
  }
}
