import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserType} from '../../../../../../../src/app/enums';
import {AdminType} from '../../../../../../../src/app/enums/admin-type.enum';
import {CallState} from '../../../../../../consumer/src/app/models/enums/call-state';
import * as fromAuth from './auth.reducer';
import {extractErrorMessage} from '../../../shared/utils/extract-error-message';
import {ReadingWeekStartDayService} from '../../../../../../../src/app/services/reading-week-start-day.service';
import moment from 'moment/moment';
import {WeekRangeType} from '../../enums/week-range-type';
import {formatDate} from '@angular/common';
import {WeekInfo} from '../../models/week-info';
import {GlobalConstants} from '../../../../../../../src/app/globals';
import {StudentResponse, TeacherResponse} from '../../../../../../../src/app/models';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey,
);

export const selectIsAuthLoading = createSelector(selectAuthState, (state) => state.authData?.callState === CallState.Loading);
export const selectIsAuthLoaded = createSelector(selectAuthState, (state) => state.authData?.callState === CallState.Loaded);
export const selectIdsAccessToken = createSelector(selectAuthState, (state) => state.authData?.data?.idsAccessToken);
export const selectFullAuthError = createSelector(selectAuthState, (state) => state?.authData?.callState === CallState.Error ? state?.authData?.error : null);
export const selectAuthError = createSelector(selectAuthState, (state) => state?.authData?.callState === CallState.Error ?
  state.authData?.error?.error?.['error_description'] ||
  state.authData?.error?.error?.['error'] ||
  state.authData?.error?.error ||
  state.authData?.error?.message ||
  state.authData?.error ||
  $localize`Error Occurred` :
  null);
export const selectMPAccessToken = createSelector(selectAuthState, state => state.authData?.data?.mpAuthResponse?.access_token);

export const selectUserSchoolsError = createSelector(selectAuthState, state => state.userSchools?.callState === CallState.Error ?
  extractErrorMessage(state.userSchools?.error) : null);

export const selectSetupUserSchoolLoading = createSelector(selectAuthState, state => state.selectedSchool?.callState === CallState.Loading);
export const selectSetupUserSchoolError = createSelector(selectAuthState, state => state.selectedSchool?.callState === CallState.Error ?
  extractErrorMessage(state.selectedSchool?.error) : null);

export const selectUserClassesLoading = createSelector(selectAuthState, state => state.userClassesData?.callState === CallState.Loading);
export const selectUserClassesLoaded = createSelector(selectAuthState, state => state.userClassesData?.callState === CallState.Loaded);
export const selectUserClassesState = createSelector(selectAuthState, state => state.userClassesData);
export const selectUserClasses = createSelector(selectAuthState, state => state.userClassesData?.data?.classes);
export const selectUserClassIds = createSelector(selectAuthState, state => state.userClassesData?.data?.classes?.map(cls => cls.classId));
export const selectUserClassesError = createSelector(selectAuthState, (state) => state.userClassesData?.callState === CallState.Error ?
  extractErrorMessage(state.userClassesData?.error) : null);

export const selectAuthFlowState = createSelector(selectAuthState, state => {
  return state.authFlowState
});

export const selectAuthFlowStateLoaded = createSelector(selectAuthState, state => {
  return state.authFlowState === CallState.Loaded
});

export const selectAuthFlowStateLoading = createSelector(selectAuthState, state => {
  return state.authFlowState === CallState.Loading
});

export const selectAuthFlowStateError = createSelector(selectAuthState, state => {
  return state.authFlowState === CallState.Error
});

export const selectUserType = createSelector(selectAuthState, (state) => +state.authData?.data?.idTokenInfo?.userType);
export const selectUserId = createSelector(selectAuthState, state => state.authData?.data?.lsAuthResponse?.userId);
export const selectUTCOffset = createSelector(selectAuthState, state => state.authData?.data?.lsAuthResponse?.utcOffset);
export const selectStudentId = createSelector(selectAuthState, selectUserType, (state, userType) => userType === UserType.Student ? state.authData?.data?.lsAuthResponse.objectId : null);
export const selectTeacherId = createSelector(selectAuthState, selectUserType, (state, userType) => userType === UserType.Teacher ? state.authData?.data?.lsAuthResponse.objectId : null);
export const selectLSDistrictId = createSelector(selectAuthState, state => state.selectedSchool?.data?.school?.districtId);
export const selectIsStudent = createSelector(selectUserType, userType => userType === UserType.Student);
export const selectIsTeacher = createSelector(selectUserType, userType => userType === UserType.Teacher);
export const isStudentOrTeacher = createSelector(
  selectUserType, userType => userType === UserType.Student || userType === UserType.Teacher
);
export const selectAdminType = createSelector(selectAuthState, state => {
  return state.authData?.data?.idTokenInfo.adminType;
});
export const selectIsSchoolOrDistrictAdmin = createSelector(selectAdminType, adminType => {
  return adminType === AdminType.SchoolAdmin || adminType === AdminType.DistrictAdmin;
});

export const selectIsSchoolAdmin = createSelector(selectAdminType, adminType => {
  return adminType === AdminType.SchoolAdmin;
});

export const selectIsDistrictAdmin = createSelector(selectAdminType, adminType => {
  return adminType === AdminType.DistrictAdmin;
});

export const selectIsProgramManager = createSelector(selectAuthState, state => {
  return state.authData?.data?.idTokenInfo?.isProgramManager;
});

export const selectCountryState = createSelector(selectAuthState, state => state.authData?.data?.lsAuthResponse?.state);
export const selectUserSessionId = createSelector(selectAuthState, state => state.userSessionId);
export const selectScreenSession = createSelector(selectAuthState, state => state.screenSession);

export const selectIsSuperAdmin = createSelector(selectUserType, userType => userType === UserType.MPSuperAdmin);
export const selectMpUsername = createSelector(selectAuthState, state => state.authData.data?.idTokenInfo?.username);
export const selectMpCustomerId = createSelector(selectAuthState, state => state.authData.data?.mpAuthResponse?.customerId);
export const selectMpClassIds = createSelector(selectAuthState, state => state.authData.data?.mpAuthResponse?.classIds);
export const selectMpUserId = createSelector(selectAuthState, state => state.authData.data?.mpAuthResponse?.userId);
export const selectMpEmail = createSelector(selectAuthState, state => state.authData.data?.mpAuthResponse?.email);
export const selectCurrentClass = createSelector(selectAuthState, state => state.selectedClass);
export const selectCurrentClassId = createSelector(selectCurrentClass, classResponse => classResponse?.classId);
export const selectCurrentUserGroupId = createSelector(selectCurrentClass, classResponse => classResponse?.userGroupId);
export const selectCurrentClassStartDate = createSelector(selectCurrentClass, classResponse => classResponse?.startDate);
export const selectCurrentClassName = createSelector(selectCurrentClass, classResponse => classResponse?.name);
export const selectCurrentClassGrade = createSelector(selectCurrentClass, classResponse => classResponse?.gradeId);

export const selectDefaultSchoolId = createSelector(selectAuthState, state => state.authData?.data?.lsAuthResponse.schoolId);
export const selectUserSchools = createSelector(selectAuthState, state => state.userSchools?.data);
export const selectHasMultipleUserSchools = createSelector(selectAuthState, state => state.userSchools?.data?.length > 1);
export const selectUserSchoolForSchoolId = (schoolId: string) => createSelector(selectAuthState, state => state.userSchools?.data?.find(sch => sch.schoolId === schoolId));
export const selectSchool = createSelector(selectAuthState, state => state.selectedSchool?.data?.school);
export const selectSchoolMetaData = createSelector(selectAuthState, state => state.selectedSchool?.data?.metaData);
export const selectSchoolId = createSelector(selectSchool, school => school?.schoolId);
export const selectSchoolName = createSelector(selectSchool, (state) => state?.userGroupOrganizationName);
export const selectUserGroupOrgId = createSelector(selectSchool, school => school?.userGroupOrganizationId);
export const selectWeekOptions = createSelector(
  selectCurrentClass,
  selectSchoolMetaData,
  (currentClass, schoolMetadata) => {
    if (!currentClass || !schoolMetadata) {
      return [];
    }

    const readingWeekInfo = ReadingWeekStartDayService.GetReadingWeekInfo(schoolMetadata.readingWeekStartDay);
    const dateFormat = 'MMM dd';

    const currentStartDate = readingWeekInfo.readingStartDate.toDate();
    const currentEndDate = readingWeekInfo.readingEndDate.toDate();

    const lastWeekStartDate = readingWeekInfo.readingStartDate.clone().subtract(1, 'week').toDate();
    const lastWeekEndDate = readingWeekInfo.readingStartDate.subtract(1, 'day').toDate();

    const last4WeeksStartDate = moment().subtract(4, 'week').startOf('day').toDate();
    const last4WeeksEndDate = moment().subtract(1, 'day').endOf('day').toDate();

    const ytdStartDate = moment(currentClass.startDate).startOf('day').toDate();
    const ytdEndDate = moment().endOf('day').toDate();

    const todayStart = moment().startOf('day').toDate();
    const todayEnd = moment().endOf('day').toDate();

    return [
      {
        type: WeekRangeType.Current,
        startDate: currentStartDate,
        endDate: currentEndDate,
        startLabel: formatDate(currentStartDate, dateFormat, $localize.locale || 'en-US'),
        endLabel: formatDate(currentEndDate, dateFormat, $localize.locale || 'en-US'),
      },
      {
        type: WeekRangeType.LastWeek,
        startDate: lastWeekStartDate,
        endDate: lastWeekEndDate,
        startLabel: formatDate(lastWeekStartDate, dateFormat, $localize.locale || 'en-US'),
        endLabel: formatDate(lastWeekEndDate, dateFormat, $localize.locale || 'en-US'),
      },
      {
        type: WeekRangeType.Last4Weeks,
        startDate: last4WeeksStartDate,
        endDate: last4WeeksEndDate,
        startLabel: formatDate(last4WeeksStartDate, dateFormat, $localize.locale || 'en-US'),
        endLabel: formatDate(last4WeeksEndDate, dateFormat, $localize.locale || 'en-US'),
      },
      {
        type: WeekRangeType.Ytd,
        startDate: ytdStartDate,
        endDate: ytdEndDate,
        startLabel: formatDate(ytdStartDate, dateFormat, $localize.locale || 'en-US'),
        endLabel: formatDate(ytdEndDate, dateFormat, $localize.locale || 'en-US'),
      },
      {
        type: WeekRangeType.Today,
        startDate: todayStart,
        endDate: todayEnd,
        startLabel: null,
        endLabel: null,
      },
      {
        type: WeekRangeType.Custom,
        startDate: null,
        endDate: null,
        startLabel: null,
        endLabel: null,
      },
    ] as WeekInfo[];
  },
);

export const selectTaggingStandard = createSelector(selectSchoolMetaData, metaData => metaData.taggingStandard);
export const selectOtherReadingStandardId = createSelector(selectSchoolMetaData, metaData => metaData.otherReadingStandardId);
export const selectLoanPeriod = createSelector(selectSchoolMetaData, (metaData) => metaData.loanPeriod ?? GlobalConstants.PublisherLoanPeriod);

export const selectStudentsClassMap = createSelector(selectAuthState, state => state.userClassesData?.data?.classStudentsMap);
export const selectStudentsByClassIds = (classIds: string[]) => createSelector(
  selectStudentsClassMap,
  (classStudentsMap) => {
    return classIds.map((classId) => classStudentsMap?.[classId] ?? []).flat()
  }
);
export const selectUniqueStudentsByClassIds = (classIds: string[]) => createSelector(
  selectStudentsByClassIds(classIds),
  (students) => {
    return Object.values(students?.reduce<Record<string, StudentResponse>>((prev, curr) => ({
      ...prev,
      [curr.userId]: curr,
    }), {}))
  }
);

export const selectStudentFromAllClasses = (studentId: string) => createSelector(
  selectStudentsClassMap,
  (classStudentsMap) => Object.values(classStudentsMap)?.flat()?.find(stu => stu.studentId === studentId)
);

export const selectTeachersClassMap = createSelector(selectAuthState, state => state.userClassesData?.data?.classTeachersMap);
export const selectTeachersByClassIds = (classIds: string[]) => createSelector(
  selectTeachersClassMap,
  (classTeachersMap) => {
    return classIds.map((classId) => classTeachersMap?.[classId] ?? []).flat()
  }
);
export const selectUniqueTeachersByClassIds = (classIds: string[]) => createSelector(
  selectTeachersByClassIds(classIds),
  (teachers) => {
    return Object.values(teachers?.reduce<Record<string, TeacherResponse>>((prev, curr) => ({
      ...prev,
      [curr.userId]: curr,
    }), {}))
  }
);

export const selectUniqueStudentsFromAllClasses = createSelector(
  selectStudentsClassMap,
  (classStudentsMap) => {
    return Object.values(classStudentsMap).flat()
  }
);

export const selectCurrentClassStudents = createSelector(
  selectCurrentClassId,
  selectStudentsClassMap,
  (classId, classMap) => classMap?.[classId] || [],
);

export const selectStudentByUserId = (userId: string) => createSelector(
  selectCurrentClassStudents,
  (students) => students?.find((student) => student.userId === userId),
);

export const selectTokenValidatedUser = createSelector(selectAuthState, state => state.tokenValidation.data);
export const selectTokenValidationLoading = createSelector(selectAuthState, state => state.tokenValidation.callState === CallState.Loading);
export const selectTokenValidationLoaded = createSelector(selectAuthState, state => state.tokenValidation.callState === CallState.Loaded);
export const selectTokenValidationError = createSelector(selectAuthState, state => state.tokenValidation.callState === CallState.Error ? extractErrorMessage(state.tokenValidation.error) : null);
export const selectIsTokenUserNotFoundError = createSelector(selectAuthState, state =>
  state.tokenValidation.callState === CallState.Error ?
  state.tokenValidation.error?.status === 404 :
  null
);

export const selectResetPasswordLoading = createSelector(selectAuthState, state => state.resetPassword.callState === CallState.Loading);
export const selectResetPasswordLoaded = createSelector(selectAuthState, state => state.resetPassword.callState === CallState.Loaded);
export const selectResetPasswordError = createSelector(selectAuthState, state => state.resetPassword.callState === CallState.Error ? extractErrorMessage(state.resetPassword.error) : null);

export const selectSetupAccountLoading = createSelector(selectAuthState, state => state.setupAccount.callState === CallState.Loading);
export const selectSetupAccountLoaded = createSelector(selectAuthState, state => state.setupAccount.callState === CallState.Loaded);
export const selectSetupAccountError = createSelector(selectAuthState, state => state.setupAccount.callState === CallState.Error ? extractErrorMessage(state.setupAccount.error) : null);

export const selectResendSetupAccountLoading = createSelector(selectAuthState, state => state.resendSetupAccount.callState === CallState.Loading);
export const selectResendSetupAccountLoaded = createSelector(selectAuthState, state => state.resendSetupAccount.callState === CallState.Loaded);
export const selectResendSetupAccountError = createSelector(selectAuthState, state => state.resendSetupAccount.callState === CallState.Error ? extractErrorMessage(state.resendSetupAccount.error) : null);
